// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
import MenuTitle from "../../../components/Menus/MenuTitle";
import Allergens from "../../../components/Menus/Allergens";
import Price from "../../../components/Menus/Price";
import Description from "../../../components/Menus/Description";
import MenuCredits from "../../../components/Menus/MenuCredits";
// import MenuDessert from "../../../components/Menus/MenuDessert";
// import MenuBites from "../../../components/Menus/MenuBites";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuBreakfast");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("breakfast menu")} />
			<div className="bodyContainer menu">
				<section>
					<div>
						<h1>
							<Trans ns="menuBreakfast">breakfast menu</Trans>.
						</h1>

						<MenuTitle bold={t("egg")} descr="da manhã" />
						<Description>
							<span className="regular">
								<Trans ns="menuBreakfast">served with</Trans>.
							</span>
							<br />
							<Trans ns="menuBreakfast">
								sourdough. homemade brioche. or ryebread. chive
							</Trans>
							.<Allergens>1.</Allergens>
						</Description>

						<p>
							<Price>7.&nbsp;</Price>
							<Trans ns="menuBreakfast">poached</Trans>.
							<Allergens>3.</Allergens>
						</p>
						<p>
							<Price>7.&nbsp;</Price>
							<Trans ns="menuBreakfast">fried</Trans>.<Allergens>3.</Allergens>
						</p>
						<p>
							<Price>7.&nbsp;</Price>
							<Trans ns="menuBreakfast">scrambled</Trans>.
							<Allergens>3.</Allergens>
						</p>
						<p>
							<Trans ns="menuBreakfast">adds</Trans>.
							<Description>
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">cheese</Trans>.
								<Allergens>7.</Allergens>
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">tomato</Trans>.
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">bacon</Trans>.
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">mushroom</Trans>.
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">hollandaise</Trans>.
								<Allergens>7.</Allergens>
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">spinach</Trans>.
								<br />
								<Price>+3.&nbsp;</Price>
								<Trans ns="menuBreakfast">avocado</Trans>.
								<br />
								<Price>+3.&nbsp;</Price>
								<Trans ns="menuBreakfast">ham</Trans>.
								<br />
								<Price>+4.&nbsp;</Price>
								<Trans ns="menuBreakfast">smoked salmon</Trans>.
								<Allergens>4.</Allergens>
								<br />
								{/* <Price>+2.</Price>
								<Trans ns="menuBreakfast">roastbeef</Trans>.
								<br /> */}
							</Description>
						</p>
						<p>
							<Price>15.&nbsp;</Price>
							bossa <Trans ns="menuBreakfast">benedict muffin</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									poached egg. bacon. spinach. red pepper powder. hollandaise
								</Trans>
								.<Allergens>1.3.7.</Allergens>
							</Description>
						</p>
						<p>
							<Price>14.&nbsp;</Price>
							<Trans ns="menuBreakfast">shakshuka</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									egg. bell pepper. tomato. feta. fresh herbs. chickpea
								</Trans>
								.<Allergens>1.3.7.</Allergens>
							</Description>
						</p>
						<p>
							<Price>13.&nbsp;</Price>
							<Trans ns="menuBreakfast">bossa egg cilbir style</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									soft boiled eggs. garlic yoghurt. roasted datterino tomatoes.
									chilli oil. herbs
								</Trans>
								.<Allergens>1.3.7.</Allergens>
							</Description>
						</p>

						<MenuTitle
							bold={t("bread")}
							descr="natural. sabroso"
							allergens={"1."}
						/>
						<Description>
							<Trans ns="menuBreakfast">served with homemade butter.</Trans>.
						</Description>
						<p>
							<Price>4.&nbsp;</Price>
							<Trans ns="menuBreakfast">sourdough</Trans>.
						</p>
						<p>
							<Price>4.&nbsp;</Price>
							<Trans ns="menuBreakfast">bossa brioche</Trans>.
						</p>
						<p>
							<Price>4.&nbsp;</Price>
							<Trans ns="menuBreakfast">bagel</Trans>.
						</p>
						<p>
							<Price>4.&nbsp;</Price>
							<Trans ns="menuBreakfast">ryebread</Trans>.
						</p>

						<p>
							<Trans ns="menuBreakfast">adds</Trans>.
							<Description>
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">cheese</Trans>.
								<Allergens>7.</Allergens>
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">tomato</Trans>.
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">bacon</Trans>.
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">mushroom</Trans>.
								<br />
								<Price>+1.5</Price>
								<Trans ns="menuBreakfast">spinach</Trans>.
								{/* <br />
								<Price>+1.&nbsp;</Price>
								<Trans ns="menuBreakfast">austrian horseradish</Trans>.
								<Allergens>7.</Allergens> */}
								<br />
								<Price>+3.&nbsp;</Price>
								<Trans ns="menuBreakfast">avocado</Trans>.
								<br />
								<Price>+3.&nbsp;</Price>
								<Trans ns="menuBreakfast">ham</Trans>.
								<br />
								<Price>+4.&nbsp;</Price>
								<Trans ns="menuBreakfast">smoked salmon</Trans>.
								<Allergens>4.</Allergens>
								<br />
								{/* <Price>+2.5</Price>
								<Trans ns="menuBreakfast">bossa hummus</Trans>.
								<Allergens>6.11.13</Allergens>.
								<br /> */}
								{/* <Price>+2.5</Price>
								<Trans ns="menuBreakfast">mangalica ham</Trans>. */}
							</Description>
						</p>
						<p>
							<Price>14.&nbsp;</Price>
							bossa <Trans ns="menuBreakfast">croissant</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									burrata. pesto. rucola. roasted tomatoes
								</Trans>
								.<Allergens>1.7.</Allergens>
							</Description>
						</p>
						{/* 
						<MenuTitle bold={t("bite of extravagance")} />
						<p>
							<Price>5.</Price>
							<Trans ns="menuBreakfast">oyster</Trans>.
							<Description>
								<Trans ns="menuBreakfast">piece. roiboos. melon</Trans>.
								<Allergens>3.7.10.14.</Allergens>
							</Description>
						</p>
						<p>
							<Price>70.</Price>
							<Trans ns="menuBreakfast">bossa caviar</Trans>.
							<Description>
								<Trans ns="menuBreakfast">30 grams. egg.créme fraîche. potato</Trans>.
								<Allergens>1.3.4.7.</Allergens>
							</Description>
						</p> */}

						{/* <MenuTitle bold={t("bossa chef selection")} />
						<p>
							<Price>8.</Price>
							<Trans ns="menuBreakfast">bread basket. homemade butter</Trans>.
							<Allergens>1.3.7.</Allergens>
						</p>
						<p>
							<Price>14.</Price>
							<Trans ns="menuBreakfast">ham. salami. pickles</Trans>.
							<Allergens>12.</Allergens>
						</p>
						<p>
							<Price>14.</Price>
							<Trans ns="menuBreakfast">cheese. chutney</Trans>.
							<Allergens>7.8.</Allergens>
						</p> */}
						{/* 
						<MenuTitle bold={t("bossa choice")} descr="vive um mix" />
						<p>
							<Trans ns="menuBreakfast">tea. or coffee</Trans>.
							<span className="small">
								{" "}
								<Trans ns="menuBreakfast">pâtisserie</Trans>.
							</span>
						</p>
						<p>
							<Trans ns="menuBreakfast">fresh orange juice</Trans>.
						</p>
						<p>
							<Price>19.</Price>
							<Trans ns="menuBreakfast">bossa shakshuka. or açai bowl</Trans>.
						</p> */}

						<MenuTitle bold={t("bossa taste")} descr="brasil. classico" />
						<p>
							<Price>8.</Price>
							<Trans ns="menuBreakfast">pão de queijo</Trans>.
							<Description>
								<Trans ns="menuBreakfast">cheese bread</Trans>.
								<Allergens>7.</Allergens>
							</Description>
							<Description>
								<span className="regular">
									<Trans ns="menuBreakfast">chef choice with</Trans>:
								</span>
								<br />
								<Price>+3.</Price>
								<Trans ns="menuBreakfast">avocado</Trans>.
								<br />
							</Description>
						</p>
						<p>
							<Price>14.</Price>
							<Trans ns="menuBreakfast">bossa açai bowl</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									peanut butter. berries. banana. granola. nuts
								</Trans>
								.<Allergens>5.8.</Allergens>
							</Description>
						</p>
						<p>
							<Price>16.</Price>
							<Trans ns="menuBreakfast">morning bagel</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									cream cheese. smoked salmon. red onions. dill. avocado.
									cucumber
								</Trans>
								.<Allergens>1.4.7.</Allergens>
							</Description>
						</p>

						<MenuTitle bold={t("granola & co")} descr="crocante. molhado" />
						<p>
							<Price>14.</Price>
							<Trans ns="menuBreakfast">chia pudding</Trans>.
							<Description>
								<Trans ns="menuBreakfast">
									coconut milk. chia. blueberries jam. granola
								</Trans>
								.<Allergens>8.</Allergens>
							</Description>
						</p>
						<p>
							<Price>9.</Price>
							bossa <Trans ns="menuBreakfast">muesli</Trans>.
							<Description>
								<Trans ns="menuBreakfast">coconut. granola. cranberry</Trans>.
								<Allergens>1.7.8.</Allergens>
							</Description>
							<Description>
								<span className="regular">
									<Trans ns="menuBreakfast">dairy choice</Trans>.
								</span>
								<br />
								<Trans ns="menuBreakfast">milk</Trans>.<Allergens>7.</Allergens>
								{" / "}
								<Trans ns="menuBreakfast">oat milk</Trans>.{" / "}
								<Trans ns="menuBreakfast">almond milk</Trans>.
								<Allergens>8.</Allergens>
								{" / "}
								<Trans ns="menuBreakfast">yoghurt</Trans>.
								<Allergens>7.</Allergens>
							</Description>
						</p>

						{/* <MenuBites /> */}

						<MenuTitle bold={t("dessert")} descr="doce fim" />
						<p>
							<Price>8.</Price>
							<Trans ns="menuDessert">crepes</Trans>.
							<Allergens>1.3.7.</Allergens>
						</p>
						<p>
							<Trans ns="menuBreakfast">adds</Trans>.
							<Description>
								<Price>+2.</Price>
								<Trans ns="menuBreakfast">dulce de leche</Trans>.
								<Allergens>7.</Allergens>
								<br />
								<Price>+2.</Price>
								<Trans ns="menuBreakfast">Nutella</Trans>.
								<Allergens>7.8.</Allergens>
							</Description>
						</p>
						<p>
							<Price>14.</Price>
							<Trans ns="menuDessert">fruit salad</Trans>.
						</p>
						<p>
							<Price>4.</Price>
							<Trans ns="menuDessert">cremerie francois ice cream</Trans>.
							<Description>
								<Trans ns="menuDessert">pistachio</Trans>.{" / "}
								<Trans ns="menuDessert">mocca</Trans>.{" / "}
								<Trans ns="menuDessert">caramel</Trans>.{" / "}
								<Trans ns="menuDessert">hazelnut</Trans>.
								<Allergens>3.7.8.</Allergens>
							</Description>
						</p>
						<p>
							<Price>4.</Price>
							<Trans ns="menuLunch">sorbet. homemade</Trans>.
							<Description>
								<Trans ns="menuLunch">lemon. / passion fruit</Trans>.
							</Description>
						</p>

						{/* <MenuTitle bold={t("brotzeit")} descr="na madeira">
							<Price>26.</Price>
						</MenuTitle>
						<Description>
							<Trans ns="menuBreakfast">
								bread. cheese. ham. salami. sea salt. pickled vegetable. hay
								butter
							</Trans>
							.<Allergens>1.7.10.22.</Allergens>
						</Description>
						<p>
							<Price>9.</Price>
							<Trans ns="menuBreakfast">bread basket</Trans>.
							<Description>
								<Trans ns="menuBreakfast">sourdough. hay butter</Trans>.
								<Allergens>1.7.10.12.</Allergens>
							</Description>
						</p> */}

						<MenuCredits extras={true} section="breakfast" untilTime={"12pm"} />
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
